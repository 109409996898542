<template>
  <v-container fluid>
    <v-row v-for="(day, index) of days" :key="index" class="ma-5">
      <div class="day-name">
        <h3>
          Horarios del  <b>{{ day.text }}</b>
        </h3>
      </div>
      <v-col cols="12" sm="4" md="4">
        <v-switch
          v-model="schedule[`${day.active}`]"
          :loading="loading"
          label="¿Abre este día? "
        ></v-switch>
      </v-col>
      <v-col cols="12" sm="8" md="8">
        <h3 class="mt-5" v-if="!schedule[day.value].length && day.active">
          Aún no se ha configurado el horario para este día.
          <a @click="add(day.value, 0)" class="ml-2"
            ><i class="fas fa-plus"></i> Agregar Horario</a
          >
        </h3>

        <v-row v-for="(item, i) of getSchedule[day.value]" :key="i">
          <v-col cols="4" sm="4">
            <v-select
              rounded
              :disabled="!schedule[`${day.active}`]"
              label="Abre"
              :loading="loading"
              filled
              :items="allowedTime"
              v-model="item.opensAt"
            />
          </v-col>
          <v-col cols="1" sm="1">
            <i class="fas fa-minus mt-4"></i>
          </v-col>
          <v-col cols="4" sm="4">
            <v-select
              rounded
              :disabled="!schedule[`${day.active}`]"
              label="Cierra"
              :loading="loading"
              filled
              :items="allowedTime"
              v-model="item.closesAt"
            />
          </v-col>
          <v-col cols="1" sm="1" md="1">
            <v-btn
              @click="add(day.value, i)"
              :dark="$vuetify.theme.dark"
              :loading="loading"
              :disabled="!schedule[`${day.active}`]"
              fab
              elevation="0"
              small
              color="primary"
            >
              <v-icon dark> fas fa-plus </v-icon>
            </v-btn>
          </v-col>

          <v-col cols="1" sm="1" md="1">
            <v-btn
              @click="remove(day.value, i)"
              :disabled="!schedule[`${day.active}`]"
              :loading="loading"
              fab
              elevation="0"
              :dark="$vuetify.theme.dark"
              small
              color="#aeaeae"
            >
              <v-icon dark> fas fa-times </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "own-schedule",
  props: ["schedule", "loading"],
  data() {
    return {
      days: [
        { text: "Lunes", value: "monday", active: "activeMonday" },
        { text: "Martes", value: "tuesday", active: "activeTuesday" },
        { text: "Miércoles", value: "wednesday", active: "activeWednesday" },
        { text: "Jueves", value: "thursday", active: "activeThursday" },
        { text: "Viernes", value: "friday", active: "activeFriday" },
        { text: "Sábado", value: "saturday", active: "activeSaturday" },
        { text: "Domingo", value: "sunday", active: "activeSunday" },
      ],
      show: true,
      allowedTime: [
        { text: "07:00 AM", value: "07:00:00" },
        { text: "07:30 AM", value: "07:30:00" },
        { text: "08:00 AM", value: "08:00:00" },
        { text: "08:30 AM", value: "08:30:00" },
        { text: "09:00 AM", value: "09:00:00" },
        { text: "09:30 AM", value: "09:30:00" },
        { text: "10:00 AM", value: "10:00:00" },
        { text: "10:30 AM", value: "10:30:00" },
        { text: "11:00 AM", value: "11:00:00" },
        { text: "11:30 AM", value: "11:30:00" },
        { text: "12:00 PM", value: "12:00:00" },
        { text: "12:30 PM", value: "12:30:00" },
        { text: "01:00 PM", value: "13:00:00" },
        { text: "01:30 PM", value: "13:30:00" },
        { text: "02:00 PM", value: "14:00:00" },
        { text: "02:30 PM", value: "14:30:00" },
        { text: "03:00 PM", value: "15:00:00" },
        { text: "03:30 PM", value: "15:30:00" },
        { text: "04:00 PM", value: "16:00:00" },
        { text: "04:30 PM", value: "16:30:00" },
        { text: "05:00 PM", value: "17:00:00" },
        { text: "05:30 PM", value: "17:30:00" },
        { text: "06:00 PM", value: "18:00:00" },
        { text: "06:30 PM", value: "18:30:00" },
        { text: "07:00 PM", value: "19:00:00" },
        { text: "07:30 PM", value: "19:30:00" },
        { text: "08:00 PM", value: "20:00:00" },
        { text: "08:30 PM", value: "20:30:00" },
        { text: "09:00 PM", value: "21:00:00" },
        { text: "09:30 PM", value: "21:30:00" },
        { text: "10:00 PM", value: "22:00:00" },
        { text: "10:30 PM", value: "22:30:00" },
        { text: "11:00 PM", value: "23:00:00" },
        { text: "11:30 PM", value: "23:30:00" },
        { text: "12:00 AM", value: "00:00:00" },
        { text: "12:30 AM", value: "00:30:00" },
        { text: "01:00 AM", value: "01:00:00" },
        { text: "01:30 AM", value: "01:30:00" },
        { text: "02:00 AM", value: "02:00:00" },
        { text: "02:30 AM", value: "02:30:00" },
        { text: "03:00 AM", value: "03:00:00" },
        { text: "03:30 AM", value: "03:30:00" },
        { text: "04:00 AM", value: "04:00:00" },
        { text: "04:30 AM", value: "04:30:00" },
        { text: "05:00 AM", value: "05:00:00" },
        { text: "05:30 AM", value: "05:30:00" },
        { text: "06:00 AM", value: "06:00:00" },
        { text: "06:30 AM", value: "06:30:00" },
      ],
    };
  },
  computed: {
    getSchedule() {
      return this.schedule;
    },
  },
  mounted() {
    console.debug(this.schedule);
  },
  methods: {
    add(day, index) {
      this.show = false;
      this.schedule[day].splice(index + 1, 0, {
        opensAt: "",
        closesAt: "",
      });

      setTimeout(() => {
        this.show = true;
      }, 500);
    },
    remove(day, index) {
      this.schedule[day] = this.schedule[day].filter((item, i) => i != index);

    },
  },
};
</script>


<style lang="scss" scoped>
.day-name {
  width: 100%;
  margin-bottom: 10px;
  background-color: rgba(166, 166, 166, 0.265);
  padding: 10px;
  height: 40px;
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  h3 {
    line-height: 0px;
    margin: 0px;
    margin-left: 10px;
  }
}

.fa-minus {
  color: rgba(128, 128, 128, 0.68);
}

.fa-times {
  color: white !important;
}

.save-schedule {
  display: flex;
  justify-content: end;
  padding-right: 35px;
}
</style>
